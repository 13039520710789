import DceEventDetails from "../types/DceEventDetails";
import DceHTMLElement from "../types/DceHTMLElement";
import DceLiveEventDetails from "../types/DceLiveEventDetails";
import DceLiveEventAction from "../types/DceLiveEventAction";

export default class DceEngineEvents {

    public static INIT_EVENT = 'init';

    public static REMOVE_EVENT = 'remove';

    public static SUBCOMP_INIT_EVENT = 'subcompInit';

    public static SUBCOMP_REMOVE_EVENT = 'subcompRemove';

    public static liveEvent(type:string, actions: DceLiveEventAction[], originalEvent: Event, elem: HTMLElement): CustomEvent<DceLiveEventDetails> {

        let det:DceLiveEventDetails = {
            type: type,
            actions: actions,
            originalEvent: originalEvent,
            element: elem
        }

        return new CustomEvent<DceLiveEventDetails>("dceLiveEvent",
            {
                detail: det
            }
        );
    }

    public static initEvent(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.INIT_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

    public static subComponentInit(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.SUBCOMP_INIT_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

    public static subComponentRemove(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.SUBCOMP_REMOVE_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

    public static removeEvent(name: string, elem: DceHTMLElement): CustomEvent<DceEventDetails> {
        return new CustomEvent<DceEventDetails>("dceEvent",
            {
                detail: {
                    type: this.REMOVE_EVENT,
                    component: name,
                    element: elem
                }
            }
        );
    }

}