import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import DropButtonInput from "ecommerce/lib/app/DropButtonInput";
import {RangeSelector} from "ecommerce/lib/index";

interface DropButtonProps {
}

@Dce("DropButton")
export default class DropButton extends DceComponent<DropButtonProps>{

    private bodyClick = (e: Event) => {
        this.bodyClickEvent(e);
    };

    private input:DropButtonInput;

    constructor(elem: DceHTMLElement, props: DropButtonProps) {
        super(elem, props);

        elem.querySelector('.c-dropbutton__label').addEventListener('click', e => {
            e.preventDefault();

            let rect = this.elem.getBoundingClientRect();

            console.log("rect", rect, window.innerWidth, rect.x - rect.width + 350);

            if(rect.left + 350 > window.innerWidth){
                this.elem.classList.add('is-right')
            }else{
                this.elem.classList.remove('is-right')
            }

            if(elem.classList.contains('is-dropped')){
                this.close();
            }else{
                this.open();
            }
        });

    }


    onSubComponentInit(component: string, elem: DceHTMLElement) {

        let comp = elem.ext.getComponent(component);
        if(comp instanceof RangeSelector){
            this.input = comp as DropButtonInput;
        }

    }

    public clear(){
        this.elem.querySelectorAll('input[type=checkbox]').forEach(inp => {
            (inp as HTMLInputElement).checked = false;
        });

        this.elem.querySelectorAll('input[type=text]').forEach(inp => {
            (inp as HTMLInputElement).value = (inp as HTMLInputElement).dataset['default'];
            (inp as HTMLInputElement).dispatchEvent(new Event('change'));
        });

        this.save();
    }

    private bodyClickEvent(e:Event){

        let target = e.target as HTMLElement;

        if(!this.elem.contains(target)){
            this.close();
        }

    }

    private open(){
        this.elem.classList.add('is-dropped');
        document.addEventListener('click', this.bodyClick);

        if(this.input){
            this.input.opened();
        }
    }

    private close(){
        this.elem.classList.remove('is-dropped');
        document.removeEventListener('click', this.bodyClick);

        this.elem.dispatchEvent(new Event('close'));
    }

    private save(){
        this.elem.classList.remove('is-dropped');
        document.removeEventListener('click', this.bodyClick);

        this.elem.dispatchEvent(new Event('save'));
    }

}
