import DceComponent from "./DceComponent";
import DceHTMLElement from "../types/DceHTMLElement";
import DceEventDetails from "../types/DceEventDetails";
import DceEngineEvents from "./DceEngineEvents";
import DceLiveEventDetails from "../types/DceLiveEventDetails";
import DceElementLiveEventListener from "./DceElementLiveEventListener";
import {DceEngine} from "../index";
import DceSimpleEventDetails from "../types/DceSimpleEventDetails";

export default class DceElement {

    private element: DceHTMLElement;

    private initializedComponents: Array<string>;

    private components: Map<string, DceComponent<any>>;

    private subComponents: Array<DceHTMLElement>;

    public constructor(element: DceHTMLElement) {
        this.initializedComponents = new Array<string>();
        this.subComponents = new Array<DceHTMLElement>();

        this.components = new Map();
        this.element = element;

        this.element.addEventListener('dceEvent', ((e: CustomEvent<DceEventDetails>) => {
            this.onDceEvent(e);
        }) as EventListener);

        this.element.addEventListener('dceLiveEvent', ((e: CustomEvent<DceLiveEventDetails>) => {
            this.onDceLiveEvent(e);
        }) as EventListener);

        this.element.addEventListener('dceSimple', (e:CustomEvent<DceSimpleEventDetails>) => {
            this.onDceSimpleEvent(e.detail.type);
        });

        this.addLiveEventListener('click');
        this.addLiveEventListener('change');
    }


    public dispatchSimpleEvent(eventType: string){

        this.element.dispatchEvent(new CustomEvent<DceSimpleEventDetails>("dceSimple", {
            detail: {
                type: eventType
            }
        }));

        DceEngine.getInstance().findSubComponents(this.element)
            .forEach(child => {

                child.dispatchEvent(new CustomEvent<DceSimpleEventDetails>("dceSimple", {
                    detail: {
                        type: eventType
                    }
                }));

            });

    }

    protected onDceSimpleEvent(eventType: string){

        this.components.forEach(c => {
            c.onSimpleEvent(eventType);
        });

    }

    protected onDceLiveEvent(e: CustomEvent<DceLiveEventDetails>){
        e.detail.actions.forEach(action => {

            if(this.components.has(action.component)){

                let comp:any = this.components.get(action.component) as any;

                if(typeof comp[action.method] === 'function'){
                    comp[action.method](e.detail.originalEvent, e.detail.element);
                }
            }
        });
    }

    protected onDceEvent(e: CustomEvent<DceEventDetails>){

        if(e.detail.type == DceEngineEvents.SUBCOMP_INIT_EVENT){

            if(!this.subComponents.indexOf(e.detail.element)){
                this.subComponents.push(e.detail.element);
            }

            this.components.forEach(c => {
                c.onSubComponentInit(e.detail.component, e.detail.element)
            });

            return;
        }

        if(e.detail.type == DceEngineEvents.SUBCOMP_REMOVE_EVENT){

            var index = this.subComponents.indexOf(e.detail.element);

            if(index > -1){
                this.subComponents.splice(index, 1);
            }

            return;
        }

        if(this.components.has(e.detail.component)){

            switch (e.detail.type) {

                case DceEngineEvents.INIT_EVENT:
                    this.components.get(e.detail.component).onInit();
                    break;

                case DceEngineEvents.REMOVE_EVENT:
                    this.components.get(e.detail.component).onRemove();
                    break;

            }

        }

    }

    public hasComponent(name: string): boolean {
        return this.initializedComponents.indexOf(name) !== -1;
    }

    public getComponent(name: string): DceComponent<any> {
        return this.components.get(name);
    }

    public addComponent(name: string, comp: DceComponent<any>) {
        this.components.set(name, comp);
        this.initializedComponents.push(name);
    }

    public getInitializedComponents(): string[] {
        return this.initializedComponents;
    }

    protected addLiveEventListener(eventType:string){
        this.element.addEventListener(eventType, new DceElementLiveEventListener(this.element, eventType), true);
    }



}
