import {ApiKeys} from "ecommerce";

export default class GoogleMapsLoader {

    /**
     * 0 - not loaded
     * 1 - loading
     * 2 - loaded
     * @private
     */
    private static status = 0;

    private static promise: Promise<void>;

    private static callbacks: Function[] = [];

    public static load(callback: Function) {

        const myApiKey = ApiKeys.getInstance().getKey("googleMapsKey");

        if (GoogleMapsLoader.status == 0) {
            GoogleMapsLoader.callbacks.push(callback);

            GoogleMapsLoader.status = 1;

            const script = document.createElement('script');

            script.src = 'https://maps.googleapis.com/maps/api/js?key=' + myApiKey + '&libraries=places';
            script.async = true;
            script.defer = true;

            script.onload = () => {
                GoogleMapsLoader.status = 2;
                GoogleMapsLoader.callbacks.forEach(c => {
                    c();
                });
                GoogleMapsLoader.callbacks = [];
            };

            document.body.append(script);

        } else if (GoogleMapsLoader.status == 1) {
            GoogleMapsLoader.callbacks.push(callback);
        } else {
            callback();
        }
    }

}