import Dce from "dce-engine/lib/decorators/Dce";
import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";

interface CookieConsentProps {
    cookie: string
}

@Dce("CookieConsent")
export default class CookieConsent extends DceComponent<CookieConsentProps> {

    constructor(elem: DceHTMLElement, props: CookieConsentProps) {
        super(elem, props);

        this.elem.querySelectorAll('.close').forEach(closeElem => {
            closeElem.addEventListener('click', evt => {
                this.close();
            });
        });

        if(document.cookie.indexOf(this.props.cookie + '=') == -1){
            this.elem.style.display = 'block';
        }

    }

    private close(){

        var oExpire = new Date();
        oExpire.setTime((new Date()).getTime() + 3600000 * 24 * 100);
        document.cookie = this.props.cookie + "=1;expires=" + oExpire + "domain=" + window.location.hostname + ";path=/";

        this.elem.style.display = 'none';
    }

}