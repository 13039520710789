import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface CartAddLinkProps {
    action: string,
    productId: string,
    priceStockCode: string,
    token: string
}

@Dce("CartAddLink")
export default class CartAddLink extends DceComponent<CartAddLinkProps>{

    private formData: FormData;

    constructor(elem: DceHTMLElement, props: CartAddLinkProps) {
        super(elem, props);

        this.formData = new FormData();
        this.formData.set('_token', this.props.token);
        this.formData.set('product_id', this.props.productId);
        this.formData.set('price_stock_code', this.props.priceStockCode);
        this.formData.set('quantity', '1');

        elem.addEventListener('click', e => {

            e.preventDefault();

            document.body.classList.add('body-loading');

            DceAjax.getInstance()
                .post(this.props.action, this.formData)
                .then((r) => {
                    console.log("FORM SUBMIT RESPNSE: ", r);
                    document.body.classList.remove('body-loading');
                });

        });

        elem.addEventListener('submit', e => {
            e.preventDefault();
            console.log("Cart SUBMIT FROM COMPOSNENES!!!!!!!");

            let form = e.target as HTMLFormElement;

            let button = form.querySelector('[type=submit]');


        });

    }


}