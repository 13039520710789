import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";

interface TabsProps {
}

@Dce("FooterCollapse")
export default class Tabs extends DceComponent<TabsProps>{

    constructor(elem: DceHTMLElement, props: TabsProps) {
        super(elem, props);

        elem.querySelectorAll('[data-acc]')
            .forEach(tab => {

                tab.addEventListener('click', e=>{
                    let t = e.target as HTMLElement;

                    t.parentElement.querySelectorAll('[data-content]')
                        .forEach(c => {

                            let etab = c as HTMLElement;

                            if(etab.parentElement.classList.contains('is-opened')){
                                etab.parentElement.classList.remove('is-opened');
                                etab.style.maxHeight = null;
                            }else{
                                etab.parentElement.classList.add('is-opened')
                                etab.style.maxHeight = etab.scrollHeight + 'px';
                            }
                        });

                });
            });


    }

    private slideDownTab(id:string){

        this.elem.querySelectorAll('[data-acc], [data-content]')
            .forEach(tab => {

                let etab = (tab as HTMLElement);

                console.log("ACC", etab, etab.dataset.id, id, etab.dataset.acc, etab.dataset.content);

                if(etab.dataset.id == id){
                    //kliknięty


                    if(etab.dataset.acc == '1'){
                        console.log("AS acc")
                        //zakladka
                        if(etab.classList.contains('is-opened')){
                            etab.classList.remove('is-opened')
                        }else{
                            etab.classList.add('is-opened')
                        }
                    }

                    if(etab.dataset.content == '1'){

                        if(etab.classList.contains('is-opened')){
                            etab.classList.remove('is-opened');

                            etab.style.maxHeight = null;
                        }else{
                            etab.classList.add('is-opened')
                            etab.style.maxHeight = etab.scrollHeight + 'px';
                        }

                    }

                }

            });

    }

    private showTab(id:string){

        this.elem.querySelectorAll('[data-tab], [data-content]')
            .forEach(tab => {

                if((tab as HTMLElement).dataset.id == id){
                    tab.classList.add('is-selected')
                }else{
                    tab.classList.remove('is-selected')
                }

            });

    }

}
