import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface OrderFormProps {
}

@Dce("OrderForm")
export default class OrderForm extends DceComponent<OrderFormProps>{

    constructor(elem: DceHTMLElement, props: OrderFormProps) {
        super(elem, props);

        elem.querySelector('form[data-confirm]')
            .addEventListener('submit', e => {

                let forms = this.elem.querySelectorAll('form:not([data-confirm])');

                console.log(forms);

                if( forms.length > 0 ){

                    e.preventDefault();

                    forms.forEach(form => {

                        form.querySelectorAll('.c-shadow-block__errors').forEach(z => z.remove());

                        let errorMsg = document.createElement('div');
                        errorMsg.classList.add('c-shadow-block__errors');
                        errorMsg.innerHTML = "Zapisz dane formularza przed kontynuowaniem";

                        form.prepend(errorMsg);

                    });

                    forms[0].scrollIntoView(true);

                }

            })


    }


}