import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";

interface ImagesGalleryProps {
}

@Dce("ImagesGallery")
export default class ImagesGallery extends DceComponent<ImagesGalleryProps>{

    private mouseDownFlag:boolean = false;
    private blockClickFlag:boolean = false;

    private mouseDownX:number = 0;
    private mouseDownY:number = 0;

    private marginX = 0;
    private marginY = 0;

    private bg: HTMLElement;

    private mouseDownCallback = (e:Event) => {
        this.mouseDown(e);
    };


    private mouseMoveCallback = (e:Event) => {
        this.mouseMove(e);
    };

    private mouseupCallback = (e:Event) => {
        this.mouseUp(e);
    };

    private current: string;

    constructor(elem: DceHTMLElement, props: ImagesGalleryProps) {
        super(elem, props);

        this.initPrevs();

        console.log("GALLERY", this.elem.querySelector('.c-product-gallery__image'));

        this.bg = (this.elem.querySelector('.c-product-gallery__image .c-product-gallery__image_bg') as HTMLElement);

        let bgurl = this.bg.style.backgroundImage
            .replace('url(','')
            .replace(')','')
            .replace(new RegExp('"', 'g'), '')
            .replace(new RegExp('\'', 'g'), '');

        console.log("BG", bgurl);

        this.current = bgurl[1];

        this.elem.querySelectorAll('.c-product-gallery__thumbs__item')
            .forEach(t => {

                t.addEventListener('click', e => {

                    console.log("THUMB CLICK", e);

                    let zoom = (t as HTMLElement).dataset.zoom;

                    console.log('zoom', zoom);

                    if(this.current != zoom){

                        this.elem
                            .querySelector('.c-product-gallery__thumbs__item.is-selected')
                            .classList.remove('is-selected');

                        t.classList.add("is-selected");

                        this.bg.classList.remove('is-zoom');
                        this.bg.style.marginLeft = '0px';
                        this.bg.style.marginTop = '0px';
                        this.current = zoom;
                        this.bg.style.backgroundImage = "url('" + zoom + "')";
                    }
                });

            })

        this.elem.querySelector('.c-product-gallery__image')
            .addEventListener('click', e => {

                if(this.blockClickFlag){
                    this.blockClickFlag = false;
                    return;
                }

                console.log("CLICK");

                if(this.bg.classList.contains("is-zoom")){

                    this.bg.classList.remove('is-zoom');
                    this.bg.style.marginLeft = '0px';
                    this.bg.style.marginTop = '0px';

                    this.bg.removeEventListener('mousedown', this.mouseDownCallback);
                    this.bg.removeEventListener('mouseup', this.mouseupCallback);
                    this.bg.removeEventListener('mousemove', this.mouseMoveCallback);

                }else{

                    let viewWidth = (e.target as HTMLElement).offsetWidth;
                    let viewHeight = (e.target as HTMLElement).offsetHeight;

                    console.log("CLICK EVENT", e);
                    let me = (e as MouseEvent);


                    //let offWidth = (viewWidth / 2) - me.clientX;
                    //let offHeight = (viewHeight / 2) - me.clientY;

                    let offWidth = (-1 * viewWidth)
                    offWidth += ((viewWidth / 2) - me.clientX) * 2;


                    let offHeight = (-1 * viewHeight);

                    offHeight += ((viewHeight / 2) - me.clientY) * 2;

                    this.marginX = offWidth;
                    this.marginY = offHeight;

                    this.bg.classList.add('is-zoom');

                    //bg.style.marginLeft = '-50%';
                    //bg.style.marginTop = '-25%';

                    //bg.style.marginLeft = 'calc(-50% + ' + offWidth + 'px)';
                    //bg.style.marginTop = 'calc(-25% + ' + offHeight + 'px)';

                    //offWidth =0;


                    console.log("ow", offWidth);
                    console.log("dw", (e.target as HTMLElement).offsetWidth)

                    this.bg.style.marginLeft = (offWidth) + 'px';//'calc(-100% + ' + (offWidth * 3) + 'px)';
                    this.bg.style.marginTop = (offHeight) + 'px';//'calc(-100% + ' + (offWidth * 3) + 'px)';

                    this.bg.addEventListener('mousedown', this.mouseDownCallback);
                    this.bg.addEventListener('mousemove', this.mouseMoveCallback);
                    this.bg.addEventListener('mouseup', this.mouseupCallback);

                    //bg.style.marginTop = '-50%';
                    //bg.style.marginTop = 'calc(-50% + ' + offHeight + 'px)';
                }

            });

    }

    private selectGalleryImage(oryginalSrc: string){

        if(this.current == oryginalSrc){
            return;
        }

        console.log('SET ORYGINAL:  ' + oryginalSrc);

        this.bg.style.backgroundImage = "url('" + oryginalSrc + "')";

        this.elem.querySelector('.c-product-gallery__thumbs__item.is-selected').classList.remove('is-selected');

        this.elem.querySelectorAll('.c-product-gallery__thumbs__item')
            .forEach(t => {

                let tt = (t as HTMLElement);

                if(tt.dataset['zoom'] == oryginalSrc){
                    tt.classList.add('is-selected');
                }

            });

        this.current = oryginalSrc;

    }

    public initPrevs(){

        let image = (this.elem.querySelector('.c-product-images__image img') as HTMLElement);
        let thumbs = (this.elem.querySelector('.c-product-images__thumbs') as HTMLElement);

        thumbs.querySelectorAll('.c-product-images__thumbs__item')
            .forEach(t => {

                (t as HTMLElement).addEventListener('mouseover', e => {

                    if(!t.classList.contains('is-selected')){
                        thumbs.querySelector('.is-selected').classList.remove('is-selected');
                        t.classList.add('is-selected');

                        image.setAttribute('src', (t as HTMLElement).dataset['medium']);

                        this.selectGalleryImage((t as HTMLElement).dataset['zoom'])

                    }

                });

            })
    }

    private preloadImages(a: string[], index = 0) {

        if(a.length <= index){
            return;
        }

        var img = new Image();
        img.onload = () => {
            this.preloadImages(a, index++);
        }
        img.src = a[index];
    }


    public mouseDown(e: Event){
        this.mouseDownFlag = true;

        this.mouseDownX = (e as MouseEvent).clientX;
        this.mouseDownY = (e as MouseEvent).clientY;

        console.log("MOUSE DOWN", e);

        this.bg.classList.add('is-non-transition');
    }

    public mouseMove(e: Event){
        if(this.mouseDownFlag) {
            let x = (e as MouseEvent).clientX;
            let y = (e as MouseEvent).clientY;

            let diffX = this.mouseDownX - x;
            let diffY = this.mouseDownY - y;

            console.log("DIFF ",  this.mouseDownX, this.mouseDownY, diffX, diffY);

            this.bg.style.marginTop = (this.marginY - diffY) + 'px';
            this.bg.style.marginLeft = (this.marginX - diffX) + 'px';

            console.log("MOUSE MOVE MARGINS", this.marginX, this.marginY);

            this.blockClickFlag = true;
        }

    }

    public mouseUp(e: Event){
        if(this.mouseDownFlag) {
            console.log("MOUSE UP", e);

            let x = (e as MouseEvent).clientX;
            let y = (e as MouseEvent).clientY;

            let diffX = this.mouseDownX - x;
            let diffY = this.mouseDownY - y;

            console.log("DIFF ", diffX, diffY);
            console.log("DIFF DS ", this.mouseDownX, this.mouseDownY);

            this.marginX = (this.marginX - diffX);
            this.marginY = (this.marginY - diffY);

            //this.bg.style.marginTop = (this.marginY) + 'px';
            //this.bg.style.marginLeft = (this.marginX) + 'px';

            this.mouseDownFlag = false;

            this.bg.classList.remove('is-non-transition');

            console.log("MOUSE UP MARGINS", this.marginX, this.marginY);
        }

    }

    private escEvent = (e:Event) => {
        console.log(e);
        if ((e as KeyboardEvent).key === "Escape"){
            this.close();
        }
    }

    public open(){
        (this.elem.querySelector('.c-product-gallery') as HTMLElement)
            .style.display = 'flex';

        document.body.classList.add('is-gallery-active');

        document.body.addEventListener('keyup', this.escEvent);
    }

    public close(){
        (this.elem.querySelector('.c-product-gallery') as HTMLElement)
            .style.display = 'none';

        document.body.classList.remove('is-gallery-active');

        document.body.removeEventListener('keyup', this.escEvent);
    }

}
