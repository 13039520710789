import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import Modal from "ecommerce/lib/app/Modal";

interface AjaxBlockProps {
    url: string
}

@Dce("AjaxBlock")
export default class AjaxBlock extends DceComponent<AjaxBlockProps>{

    constructor(elem: DceHTMLElement, props: AjaxBlockProps) {
        super(elem, props);

        let loaderDiv = document.createElement('div');
        loaderDiv.classList.add('loader');
        elem.append(loaderDiv);
        elem.classList.add('is-loading');

        DceAjax.getInstance()
            .get(props.url)
            .then((r) => {

                if(r.hasResponse()){
                    this.elem.replaceWith(
                        this.createElementFromHTML(r.response)
                    );
                }

            });

    }

    private createElementFromHTML(htmlString: string):Node {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    }

}
