import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface CartAddFormProps {
}

@Dce("QuantityInput")
export default class QuantityInput extends DceComponent<CartAddFormProps>{

    private quantityInput: HTMLInputElement;

    constructor(elem: DceHTMLElement, props: CartAddFormProps) {
        super(elem, props);
        this.quantityInput = elem.querySelector('input[name=quantity]')
    }

    public decrementQuantity(e: Event){
        this.changeQuantity(v => v-1);
    }

    public incrementQuantity(e: Event){
        this.changeQuantity(v => v+1);
    }

    private changeQuantity(callback: (i:number) => number){

        let quant = parseInt(this.quantityInput.value);
        quant = callback(quant);
        if(quant == null || quant <= 0){
            quant = 1;
        }

        this.quantityInput.value = quant + "";

        this.quantityInput.dispatchEvent(new Event('change', { bubbles: true }))
    }

}