import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";

interface InlineModalProps {
}

@Dce("InlineModal")
export default class InlineModal extends DceComponent<InlineModalProps>{

    constructor(elem: DceHTMLElement, props: InlineModalProps) {
        super(elem, props);
    }

    public hide(){
        this.elem.dispatchEvent(new CustomEvent('hide'));
        this.elem.style.display = 'none';
    }
}
