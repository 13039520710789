import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface CartAddFormProps {
}

@Dce("CartAddForm")
export default class CartAddForm extends DceComponent<CartAddFormProps>{

    constructor(elem: DceHTMLElement, props: CartAddFormProps) {
        super(elem, props);

        elem.addEventListener('submit', e => {
            e.preventDefault();
            console.log("Cart SUBMIT FROM COMPOSNENES!!!!!!!");

            let form = e.target as HTMLFormElement;

            let button = form.querySelector('[type=submit]');
            button.classList.add('is-loading')

            DceAjax.getInstance()
                .post(form.action, new FormData(form))
                .then((r) => {
                    console.log("FORM SUBMIT RESPNSE: ", r);

                    button.classList.remove('is-loading')
                });

        });

    }


}