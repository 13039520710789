import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import Modal from "ecommerce/lib/app/Modal";

interface AjaxFormProps {
    closeModal?: boolean,
    buttonLoading?: boolean;
    bodyLoading?: boolean;
}

@Dce("AjaxForm")
export default class AjaxForm extends DceComponent<AjaxFormProps>{

    constructor(elem: DceHTMLElement, props: AjaxFormProps) {
        super(elem, props);

        let loaderDiv = document.createElement('div');
        loaderDiv.classList.add('loader');
        elem.append(loaderDiv);

        let button:HTMLElement = null;
        if(this.props.buttonLoading){
            button = this.elem.querySelector('button[type=submit]');
        }

        console.log("BODYYYY LOADING ", this.props.bodyLoading);

        elem.addEventListener('submit', e => {
            e.preventDefault();

            if(elem.classList.contains('is-loading')){
                return;
            }

            let form = e.target as HTMLFormElement;

            elem.classList.add('is-loading');

            if(null != button){
                button.classList.add('is-loading');
            }

            let href = form.action;

            if(this.props.bodyLoading){
                document.body.classList.add('body-loading');
            }

            DceAjax.getInstance()
                .post(href, new FormData(form))
                .then((r) => {

                    if(!r.isValid()){
                        form.replaceWith(
                            this.createElementFromHTML(r.response)
                        );
                    }else{

                        if(r.hasResponse()){
                            form.replaceWith(
                                this.createElementFromHTML(r.response)
                            );
                        }

                        if(this.props.closeModal){
                            let modal = elem.closest('.c-modal') as DceHTMLElement;

                            if(modal) {
                                let modalObj = (modal.ext.getComponent('Modal') as Modal);
                                modalObj.close();
                            }
                        }

                    }

                    if(null != button){
                        button.classList.remove('is-loading');
                    }

                    document.body.classList.remove('body-loading');
                    elem.classList.remove('is-loading')
                });
        });

    }

    private createElementFromHTML(htmlString: string):Node {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    }

}
