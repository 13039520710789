import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";

interface ShowDivProps {
    selector: string,
    display: string
}

@Dce("ShowDiv")
export default class ShowDiv extends DceComponent<ShowDivProps>{

    constructor(elem: DceHTMLElement, props: ShowDivProps) {
        super(elem, props);

        let target = document.querySelector(this.props.selector);

        console.log("TARGET", target);

        let elemDisplay = getComputedStyle(elem).display;

        elem.addEventListener('click', e => {
            (target as HTMLElement).style.display = this.props.display;
            elem.style.display = 'none';
        });

        target.addEventListener('hide', e => {
            elem.style.display = elemDisplay;
        });

    }


    defaultProps(): ShowDivProps {
        return {
            selector: '',
            display: 'block'
        }
    }
}
