import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface CustomerFormProps {
}

@Dce("CustomerForm")
export default class CustomerForm extends DceComponent<CustomerFormProps>{

    constructor(elem: DceHTMLElement, props: CustomerFormProps) {
        super(elem, props);

        this.elem.querySelectorAll('input[name="customer_form[is_company]"]')
            .forEach(elem => {
                elem.addEventListener('change', e => {
                    this.setupView();
                });
            });

        this.setupView();
    }

    public setupView(): void{
        let input = this.elem.querySelector('input[name="customer_form[is_company]"]:checked') as HTMLInputElement;
        let val = input.value;

        if(val == '1'){
            //firma

            this.changeVisibility('.group-company', true);
            this.changeVisibility('.group-not-company', false);

        }else{
            //osoba fizyczna
            this.changeVisibility('.group-company', false);
            this.changeVisibility('.group-not-company', true);
        }
    }


    public changeVisibility(selector:string, visible:boolean){

        this.elem.querySelectorAll(selector)
            .forEach(elem => {
                if(visible){
                    (elem as HTMLElement).style.display = null;
                }else{
                    (elem as HTMLElement).style.display = 'none';
                }
            });

    }

}