import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface RegisterPaymentProps {
    url: string;
}

@Dce("RegisterPayment")
export default class OrderForm extends DceComponent<RegisterPaymentProps>{

    constructor(elem: DceHTMLElement, props: RegisterPaymentProps) {
        super(elem, props);

        DceAjax.getInstance()
            .get(props.url)
            .then(resp => {
                if(!resp.isValid()){
                    this.showError();
                    return;
                }

                if(resp.hasDataPart()){
                    let ar = resp.data as AjaxResp;

                    if(ar.error){
                        this.showError();
                    }else{
                        this.handleSuccess(ar.redirect);
                    }

                }else{
                    this.showError();
                }

            })

    }

    private handleSuccess(redirect: string){

        this.findByRole('success').forEach(e => {

            let eh = (e as HTMLElement);
            eh.querySelectorAll('a').forEach(a => {
                (a as HTMLElement).setAttribute('href', redirect)
            });

            eh.style.display = 'block';

        })

        this.findByRole('loading').forEach(e => {
            (e as HTMLElement).style.display = 'none';
        });

        setTimeout(() => {
            window.location.replace(redirect);
        }, 2000);

    }

    private showError(){
        this.findByRole('loading').forEach(e => {
            (e as HTMLElement).style.display = 'none';
        });

        this.findByRole('error').forEach(e => {
            (e as HTMLElement).style.display = 'block';
        })
    }

    private findByRole(role:string): NodeListOf<Element>{
        return this.elem.querySelectorAll('*[data-role="' + role +'"]')
    }

}


interface AjaxResp {
    error: boolean;
    redirect?: string;
}

