import Dce from "dce-engine/lib/decorators/Dce";
import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import {DceAjax} from "dce-ajax";
import GoogleMapsLoader from "ecommerce/lib/integration/GoogleMapsLoader";

interface PlaceChooserProps {
    service: string,
    address?: string,
    startLat?: number
    startLng?: number
    code?: string,
    saveUrl?: string
}

@Dce("PlaceChooser")
export default class PlaceChooser extends DceComponent<PlaceChooserProps> {

    private initialized: boolean = false;
    private map: HTMLElement;
    private input: HTMLInputElement;

    private googleMap: google.maps.Map;

    private startPoint: any = {lat: 51.1078852, lng: 17.0385376};
    private startZoom = 10;

    private points: FurgonetkaPoint[] = [];
    private icons: IconGenenerator;

    private pointElement: PointDesciptionElement;

    private selectedMarker: google.maps.Marker;

    constructor(elem: DceHTMLElement, props: PlaceChooserProps) {
        super(elem, props);

        this.icons = new IconGenenerator(this.props.service);

        if (this.props.startLng && this.props.startLat) {
            this.startPoint = {
                lat: this.props.startLat,
                lng: this.props.startLng
            };
            this.startZoom = 14;
        }

        this.map = this.elem.querySelector('[data-role=map]');
        this.input = this.elem.querySelector('input[data-role=input]');
        this.pointElement = new PointDesciptionElement(this.elem.querySelector('[data-role=point]'), this.props.saveUrl);

        this.pointElement.onClose(() => {
            if (this.selectedMarker) {
                this.selectedMarker.setIcon(this.icons.icon(this.pointElement.point));
                this.selectedMarker = null;
            }
        });

        this.loadGoogleMaps();
    }

    private loadGoogleMaps() {

        const myApiKey = `AIzaSyAjB9Py1__0gk_Rf3eCBgyGjArjeznNsow`;

        let lat = 51.1078852;
        let lng = 17.0385376;
        const zoom = 12;

        console.log("AAA", this.props);

        if (this.props.address) {
            console.log('SET ADDRESS: ', this.props.address);
            this.input.value = this.props.address;
        }

        navigator.geolocation.getCurrentPosition((position) => {
            // Center on user's current location if geolocation prompt allowed
            this.startPoint = {lat: position.coords.latitude, lng: position.coords.longitude};
            this.startZoom = 14;

            if (this.googleMap) {
                this.googleMap.setCenter(this.startPoint);
                this.googleMap.setZoom(this.startZoom);
            }

        }, function (positionError) {

        });


        GoogleMapsLoader.load(() => {

            console.log("GOOGLE MAPS ON LOAD!!!!");

            if (!this.props.startLng || !this.props.startLat) {
                if (this.props.address) {
                    let geocoder = new google.maps.Geocoder();

                    geocoder.geocode(
                        {
                            address: this.props.address,
                            componentRestrictions: {
                                country: "pl"
                            }
                        },
                        (r, s) => {
                            console.log("XXX GECODE RESULT", s, r);

                            if (s == google.maps.GeocoderStatus.OK) {

                                this.startPoint = {
                                    lat: r[0].geometry.location.lat(),
                                    lng: r[0].geometry.location.lng()
                                }
                                this.startZoom = 15;

                            }

                            this.createMaps();
                        }
                    );
                }else{
                    this.createMaps();
                }
            }else{
                this.createMaps();
            }

        });


    }


    private createMaps() {
        //
        // const iconMarker = {
        //     url: "/maps/inpost-marker.png",
        //     // This marker is 20 pixels wide by 32 pixels high.
        //     size: new google.maps.Size(24, 32),
        //     // The origin for this image is (0, 0).
        //     origin: new google.maps.Point(0, 0),
        //     // The anchor for this image is the base of the flagpole at (0, 32).
        //     anchor: new google.maps.Point(12, 32),
        // };
        //
        // const iconMarkerActive = {
        //     url: "/maps/inpost-active-marker.png",
        //     // This marker is 20 pixels wide by 32 pixels high.
        //     size: new google.maps.Size(24, 32),
        //     // The origin for this image is (0, 0).
        //     origin: new google.maps.Point(0, 0),
        //     // The anchor for this image is the base of the flagpole at (0, 32).
        //     anchor: new google.maps.Point(12, 32),
        // };


        this.googleMap = new google.maps.Map(this.map, {
            center: this.startPoint,
            zoom: this.startZoom,
            mapTypeId: "roadmap",
            zoomControl: true,
            scaleControl: false,
            mapTypeControl: false,
            disableDefaultUI: true,
            minZoom: 12,
        });

        const autocomplete = new google.maps.places.Autocomplete(this.input, {
            componentRestrictions: {
                country: 'pl'
            }
        });

        autocomplete.addListener('place_changed', () => {

            console.log("PLACE CHANGED", autocomplete);

            if (autocomplete.getPlace()) {
                let place = autocomplete.getPlace().geometry.location;
                if (place) {
                    this.googleMap.setCenter(place);
                    this.googleMap.setZoom(15);
                }
            }

        });


        let pointsMarkers: google.maps.Marker[] = [];

        this.googleMap.addListener('bounds_changed', () => {
            console.log("ZOOOM", this.googleMap.getZoom());
        });

        this.googleMap.addListener('idle', () => {

            console.log("IDLE", this.googleMap.getBounds());

            let ne = this.googleMap.getBounds().getNorthEast();
            let sw = this.googleMap.getBounds().getSouthWest();

            let furgonetkaReq: FurgonetkaPointsReq = {
                location: {
                    points_max_distance: 50,
                    coordinates: {
                        latitude: this.googleMap.getBounds().getCenter().lat(),
                        longitude: this.googleMap.getBounds().getCenter().lng(),
                    }
                },
                filters: {
                    services: [this.props.service],
                    point_types: [],
                    map_bounds: {
                        north_west: {
                            latitude: ne.lat(),
                            longitude: sw.lng()
                        },
                        north_east: {
                            latitude: ne.lat(),
                            longitude: ne.lng()
                        },
                        south_east: {
                            latitude: sw.lat(),
                            longitude: ne.lng()
                        },
                        south_west: {
                            latitude: sw.lat(),
                            longitude: sw.lng()
                        }
                    }
                }
            };

            console.log(JSON.stringify(furgonetkaReq));

            this.elem.classList.add('loading');

            let formData = new FormData();
            formData.append('req', JSON.stringify(furgonetkaReq));

            DceAjax.getInstance()
                .post('/cod_points', formData)
                .then(resp => {
                    console.log(resp);
                    this.elem.classList.remove('loading');

                    let points = resp.getData('points');

                    console.log(points);

                    pointsMarkers.forEach((m) => {
                        m.setMap(null);
                    });

                    pointsMarkers = [];
                    this.points = [];

                    (points as FurgonetkaPoint[]).forEach(point => {

                        if (!point.active) {
                            return;
                        }

                        this.points.push(point);

                        let marker = new google.maps.Marker({
                            map: this.googleMap,
                            anchorPoint: new google.maps.Point(0, -29),
                            visible: true,
                            position: {
                                lat: point.coordinates.latitude,
                                lng: point.coordinates.longitude
                            },
                            clickable: true,
                            icon: this.icons.icon(point)
                        });

                        if (this.pointElement) {

                            if (point.code == this.pointElement.code) {
                                this.selectedMarker = marker;
                                this.selectedMarker.setIcon(this.icons.iconSelected(point));
                            }

                            if (!this.initialized && this.props.code == point.code) {
                                this.selectedMarker = marker;
                                this.selectedMarker.setIcon(this.icons.iconSelected(point));

                                this.pointElement.updateAddress(point);
                                this.pointElement.show();
                            }

                        }

                        marker.addListener('click', () => {
                            console.log(point.code + " " + point.description);
                            this.pointElement.updateAddress(point);
                            this.pointElement.show();

                            if (this.selectedMarker) {
                                this.selectedMarker.setIcon(this.icons.icon(point));
                            }

                            marker.setIcon(this.icons.iconSelected(point));

                            this.selectedMarker = marker;
                        });

                        pointsMarkers.push(marker);

                    });

                    this.initialized = true;

                });


        });
    }

}


class PointDesciptionElement {

    private element: HTMLElement;

    private _code: String;
    private _point: FurgonetkaPoint;

    private name: HTMLElement;
    private address: HTMLElement;
    private close: HTMLElement;
    private type: HTMLElement;
    private hours: HTMLElement;

    private form: HTMLFormElement;

    private onCloseCallback: () => void;

    constructor(element: HTMLElement, saveUrl: string) {
        this.element = element;

        this.name = this.element.querySelector('[data-role="point-name"]');
        this.address = this.element.querySelector('[data-role="point-address"]');
        this.close = this.element.querySelector('[data-role="point-close"]');
        this.type = this.element.querySelector('[data-role="point-type"]');
        this.hours = this.element.querySelector('[data-role="point-hours"]');

        this.form = this.element.querySelector('form[data-role="point-save-form"]');
        this.form.action = saveUrl;

        this.close.addEventListener('click', (e) => {
            this.hide();
            if (this.onCloseCallback) {
                this.onCloseCallback();
            }
        });
    };


    get code(): String {
        return this._code;
    }

    get point(): FurgonetkaPoint{
        return this._point;
    }

    public onClose(callback: () => void) {
        this.onCloseCallback = callback;
    }

    public updateAddress(point: FurgonetkaPoint) {
        this._code = point.code;
        this._point = point;

        let name = (point.type ? point.type + ": " : "") + point.code;

        this.type.innerHTML = point.name;

        this.name.innerHTML = name;
        let address = point.address.street + "<br/>" + point.address.postcode + " " + point.address.city + "<br/>";
        address += point.description;

        this.address.innerHTML = address;

        let dayKeys = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        let days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];

        let hours = '';

        dayKeys.forEach((k, i) => {

            let text = '';

            let h = point.opening_hours[k as keyof FurgonetkaPointOpeningHours];

            if(h == null){
                text = 'zamknięte';
            }else if(h.start_hour == '00:00' && h.end_hour == '23:59'){
                text = 'całodobowo';
            }else{
                text = h.start_hour + " - " + h.end_hour;
            }

            hours += '<b>' + days[i] + "</b>: " + text + "<br/>";

        });

        this.hours.innerHTML = hours;

        this.setFormValue('point_code', point.code);
        this.setFormValue('point_name', name);

        this.setFormValue('point_lat', String(point.coordinates.latitude));
        this.setFormValue('point_lng', String(point.coordinates.longitude));
        this.setFormValue('point_json', JSON.stringify(point));

    }

    public setFormValue(name:string, value:string){
        let input = this.form.querySelector('input[name="' + name +'"]');
        if(input){
            (input as HTMLInputElement).value = value;
        }
    }

    public show() {
        this.element.style.display = 'block';
    }

    public hide() {
        this.element.style.display = 'none';
    }

}


class IconGenenerator {

    private service: string;

    private cache: Map<String, any>

    constructor(service: string) {
        this.service = service;
        this.cache = new Map<String, any>();
    }

    public icon(point: FurgonetkaPoint){

        let url = this.typeName(point, false);

        if(this.cache.has(url)){
            return this.cache.get(url);
        }

        let icon = {
            url: url,
            // This marker is 20 pixels wide by 32 pixels high.
            size: new google.maps.Size(24, 32),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(12, 32),
        }

        this.cache.set(url, icon);
        return icon;
    }

    public iconSelected(point: FurgonetkaPoint){

        let url = this.typeName(point, true);

        if(this.cache.has(url)){
            return this.cache.get(url);
        }

        let icon = {
            url: url,
            // This marker is 20 pixels wide by 32 pixels high.
            size: new google.maps.Size(24, 32),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(12, 32),
        }

        this.cache.set(url, icon);
        return icon;

    }

    private typeName(point: FurgonetkaPoint, selected: boolean): string{

        let a = selected ? '-active' : '';

        if(this.service == 'inpost'){
            return '/maps/' + this.service + a +'-marker.png';
        }

        if(this.service == 'dhl'){

            let service = this.service;

            if(null != point.name){

                if(point.name.toLowerCase().indexOf('żabka') > -1){
                    service = 'dhl-zabka';
                }else if(point.name.toLowerCase().indexOf('biedronka') > -1){
                    service = 'dhl-biedronka';
                }else{
                    service = 'any';
                }

            }

            return '/maps/' + service + a +'-marker.png';

        }

        return '/maps/any' + a +'-marker.png';
    }

}

interface FurgonetkaPoint {
    active: boolean,
    code: string,
    name: string,
    type: string,
    address: {
        city: string,
        postcode: string,
        street: string
    },
    cod: true,
    coordinates: {
        latitude: number,
        longitude: number
    },
    description: string,
    is_delivery_point: boolean,
    opening_hours: FurgonetkaPointOpeningHours

}

interface FurgonetkaPointOpeningHours {

    monday: {
        start_hour: string,
        end_hour: string
    },
    tuesday: {
        start_hour: string,
        end_hour: string
    },
    wednesday: {
        start_hour: string,
        end_hour: string
    },
    thursday: {
        start_hour: string,
        end_hour: string
    },
    friday: {
        start_hour: string,
        end_hour: string
    },
    saturday: {
        start_hour: string,
        end_hour: string
    },
    sunday: {
        start_hour: string,
        end_hour: string
    },

}

interface FurgonetkaPointsReq {
    location: {
        points_max_distance: number,
        coordinates: {
            latitude: number,
            longitude: number
        }
    },
    filters: {
        services: string[],
        point_types: string[],

        map_bounds: {
            north_west: {
                latitude: number,
                longitude: number
            },

            north_east: {
                latitude: number,
                longitude: number
            },

            south_west: {
                latitude: number,
                longitude: number
            },

            south_east: {
                latitude: number,
                longitude: number
            }
        }

    }
}


/*

        let circle = new google.maps.Circle({
            radius: 1000,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: this.googleMap,
            visible: false
        });

        let polygon = new google.maps.Polygon({
            strokeColor: "#629cb3",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#96e2cc",
            fillOpacity: 0.35,
            map: this.googleMap,
            visible: false
        });

 */