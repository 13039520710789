import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import Modal from "ecommerce/lib/app/Modal";

interface SaveOnChangeProps {
    postUrl: string;
}

@Dce("SaveOnChange")
export default class SaveOnChange extends DceComponent<SaveOnChangeProps>{
    private input: HTMLTextAreaElement | HTMLInputElement;

    constructor(elem: DceHTMLElement, props: SaveOnChangeProps) {
        super(elem, props);
        this.input = elem as any as HTMLTextAreaElement|HTMLInputElement

        this.input.addEventListener('change', e => {
            this.save();
        });
    }

    public save(){
        let data = new FormData();
        data.set('value', this.input.value);

        DceAjax.getInstance()
            .post(this.props.postUrl, data)
            .then((r) => {

            });
    }
}
