import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";

interface CartAddFormProps {
    url: string;
}

@Dce("CartItem")
export default class CartAddForm extends DceComponent<CartAddFormProps>{

    private url: string;
    private csrfToken: string

    constructor(elem: DceHTMLElement, props: CartAddFormProps) {
        super(elem, props);

        this.url = props.url;

        this.csrfToken = (this.elem.querySelector('input[name=_token]') as HTMLInputElement).value;

        let quantityInput = elem.querySelector('input[name=quantity]');

        if(quantityInput) {
            quantityInput
                .addEventListener('change', e => {

                    this.elem.classList.add('is-loading');

                    let input = e.target as HTMLInputElement;

                    let postData = {
                        'quantity': input.value
                    }

                    this.sendData(postData);
                });
        }

    }

    public delete(e:Event){
        this.sendData({
            'quantity': 0
        });
    }

    private sendData(postData: any){
        this.elem.classList.add('is-loading');

        postData['_token'] = this.csrfToken;


        var formData = new FormData();

        for ( var key in postData ) {
            formData.append(key, postData[key]);
        }


        DceAjax.getInstance()
            .post(this.url, formData)
            .then((r) => {
                console.log("FORM SUBMIT RESPNSE: ", r);

                this.elem.classList.remove('is-loading')
            });

    }


}