import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import Axios, {CancelTokenSource, AxiosStatic} from "axios";


interface SearchAutocompleteProps {
}

@Dce("SearchAutocomplete")
export default class SearchAutocomplete extends DceComponent<SearchAutocompleteProps> {

    private reqCancelTokenSource: CancelTokenSource;

    private timeout: any;

    private autocompleteWrapper: HTMLElement;

    private input: HTMLInputElement;

    private blurTimeout: any = false;

    constructor(elem: DceHTMLElement, props: SearchAutocompleteProps) {
        super(elem, props);

        this.autocompleteWrapper = elem.querySelector('[data-role="autocomplete"]');

        this.input = elem.querySelector('input[type="text"]');

        this.input.addEventListener('focus', e => {
            if((this.input as HTMLInputElement).value != '') {
                this.autocompleteWrapper.style.display = "block";
            }
        });

        this.input.addEventListener('blur', e => {
            this.blurTimeout = setTimeout(() => {
                this.autocompleteWrapper.style.display = null;
            }, 200);
        });

        this.input.addEventListener('input', e => {
            let value = (e.target as HTMLInputElement).value;
            this.makeCall(value);
        });

    }

    public makeCall(value: string) {
        if (null != this.reqCancelTokenSource) {
            console.log('Canceling req');
            this.reqCancelTokenSource.cancel("Canceling request");
            this.reqCancelTokenSource = null;
        }

        if (null != this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        if (value != '' && value.length > 3) {

            console.log('value', value);

            this.autocompleteWrapper.style.display = "block";
            this.elem.classList.add("is-autocomplete-loading");

            this.timeout = setTimeout(() => {
                this.reqCancelTokenSource = DceAjax.cancelTokenSource();
                this.timeout = null;

                let data = new FormData();
                data.set('q', value);

                DceAjax.getInstance()
                    .post("/search_autocomplete", data, {
                        cancelToken: this.reqCancelTokenSource.token
                    }).then(e => {
                    this.reqCancelTokenSource = null;
                    this.elem.classList.remove("is-autocomplete-loading");
                });
            }, 300);

        }else{
            this.autocompleteWrapper.style.display = null;
        }
    }



    public term(e:Event, elem:HTMLElement){

        if(null != this.blurTimeout) {
            clearTimeout(this.blurTimeout);
        }
        this.blurTimeout = null;

        let term = elem.dataset['term'];

        console.log("TTTTTTTT", term);

        if(term){
            (this.input as HTMLInputElement).value = term;
            this.makeCall(term);
            this.input.focus();
        }


    }

}
