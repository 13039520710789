import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";

interface AjaxLinkProps {
    loadOnClosest?: string,
    bodyLoading?: boolean
}

@Dce("AjaxLink")
export default class AjaxLink extends DceComponent<AjaxLinkProps> {

    private closestWithLoading: Element = null;

    constructor(elem: DceHTMLElement, props: AjaxLinkProps) {
        super(elem, props);

        elem.addEventListener('click', e => {
            e.preventDefault();

            if (this.loadingInProgress()) {
                return;
            }

            this.startLoading();

            let href = elem.getAttribute('href');
            if(!href){
                href = elem.getAttribute('data-href');
            }

            console.log("HREF ", href);

            DceAjax.getInstance()
                .get(href)
                .then((r) => {
                    this.stopLoading();
                });
        });

    }

    private loadingInProgress(): boolean {
        return this.elem.classList.contains('is-loading');
    }

    private startLoading() {
        this.elem.classList.add('is-loading');

        if (this.props.loadOnClosest) {
            let closest = this.elem.closest(this.props.loadOnClosest)
            if (null != closest) {
                closest.classList.add('is-loading');
                this.closestWithLoading = closest;
            }
        }

        if(this.props.bodyLoading){
            document.body.classList.add('body-loading');
        }
    }

    private stopLoading() {
        this.elem.classList.remove('is-loading');

        if (null != this.closestWithLoading) {
            this.closestWithLoading.classList.remove('is-loading');
            this.closestWithLoading = null;
        }

        document.body.classList.remove('body-loading');
    }
}
