import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax} from "dce-ajax";
import {ApiKeys} from "ecommerce/lib/index";


declare var ApaczkaMap: any;


interface ApaczkaPointButtonProps {
    post_url: string;
    customer_address_url: string;
    point: string;
    supplier: string;
}

@Dce("ApaczkaPointButton")
export default class ApaczkaPointButton extends DceComponent<ApaczkaPointButtonProps>{

    private static loaded: boolean = false;

    private apaczkaMap: any;

    constructor(elem: DceHTMLElement, props: ApaczkaPointButtonProps) {
        super(elem, props);

        elem.addEventListener('click', e => {
            e.preventDefault();
            this.handleClick();
        });

    }

    private showMap(){

        if(null == this.apaczkaMap){

            let appId = ApiKeys.getInstance().getKey("apaczkaAppId")

            this.apaczkaMap = new ApaczkaMap({
                app_id : appId,
                onChange : (r:any) => this.changePoint(r),
                criteria: [
                    {field: 'services_receiver', operator: 'eq', value: true}
                ],
                hideServicesCod: true
            });

            this.apaczkaMap.setFilterSupplierAllowed(
                [this.props.supplier], [this.props.supplier]
            )

        }

        //lokalizacja na mapie wg. punktu, nie potrzebujemy adresu
        if(this.props.point){

            let point: {[k: string]: any} = null;
            point = {
                foreign_access_point_id: this.props.point,
                supplier: this.props.supplier
            }

            this.stopLoading();

            this.apaczkaMap.show({
                point: point
            });

            return;
        }


        //lokalizacja na mapie wg adresu
        DceAjax.getInstance()
            .get(this.props.customer_address_url)
            .then(resp => {

                this.stopLoading();

                let city = resp.data['city'];
                let street = resp.data['street'];

                let address: {[k: string]: any} = null;
                if(null != street && null != city){
                    address = {
                        city: city,
                        street: street
                    }
                }

                if(null != address){
                    this.apaczkaMap.show({
                        address: address
                    });
                }else{
                    this.apaczkaMap.show();
                }

            });

    }

    private changePoint(record: any){
        console.log("APACZKA POINT", record);

        //convert to furgonetka format
        let json: {[k: string]: any} = {
            code: record['foreign_access_point_id'],
            name: record['name'],
        };

        try {
            json = {
                code: record['foreign_access_point_id'],
                name: record['name'],
                coordinates: {
                    latitude: record['latitude'],
                    longitude: record['longitude']
                },
                address: {
                    postcode: record['postal_code'],
                    street: record['street'],
                    city: record['city'],
                    country_code: record['country_code'],
                },
                opening_hours: {}
            };

            let days = [
                ['mon', 'monday'],
                ['tue', 'tuesday'],
                ['wed', 'wednesday'],
                ['thu', 'thursday'],
                ['fri', 'friday'],
                ['sat', 'saturday'],
                ['sun', 'mondsundayay'],
            ];

            for (const day of days) {
                let s = record['open_hours'][day[0]]

                json.opening_hours[day[1]] = {
                    start_hour: s[0],
                    end_hour: s[1]
                }
            }
        }catch (e){

        }

        let formData = new FormData();

        formData.set('point_code', record['foreign_access_point_id']);
        formData.set('point_name', record['name']);
        formData.set('point_lat', record['latitude']);
        formData.set('point_lng', record['longitude']);
        formData.set('point_json', JSON.stringify(json));

        this.startLoading();

        DceAjax.getInstance()
            .post(this.props.post_url, formData)
            .then(resp => {
                this.stopLoading();
            });

    }

    private handleClick(){

        this.startLoading();

        if(ApaczkaPointButton.loaded){
            this.showMap();
            return;
        }

        var script = document.createElement('script');
        script.onload = () => {
            ApaczkaPointButton.loaded = true;
            this.showMap();
        };
        script.src = 'https://mapa.apaczka.pl/client/apaczka.map.js';
        document.head.appendChild(script);

    }

    private startLoading() {
        document.body.classList.add('body-loading');
    }

    private stopLoading() {
        document.body.classList.remove('body-loading');
    }

}