import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";

interface ModalProps {
}

@Dce("Modal")
export default class Modal extends DceComponent<ModalProps>{

    constructor(elem: DceHTMLElement, props: ModalProps) {
        super(elem, props);
        console.log("INIT MODAL !!!!!!!!!!!!!!!!!");
    }

    public close(){
        this.elem.remove();
    }

}
