import DceEngineEvents from "./DceEngineEvents";
import DceLiveEventAction from "../types/DceLiveEventAction";



export default class DceElementLiveEventListener implements EventListenerObject {

    private element: HTMLElement;
    private eventType: string;

    constructor(element: HTMLElement, eventType: string) {
        this.element = element;
        this.eventType = eventType;
    }

    /**
     * Akcja zapisywana jako:
     * data-xclick="#ID.komponent::metoda;[...]"
     * data-xclick="komponent::metoda;[xxx]"
     * @param evt
     */
    public handleEvent(evt: Event): void {

        console.log("COMP ELEM", this.element, this.eventType);

        let queried: NodeListOf<Element> = this.element.querySelectorAll('[data-x' + this.eventType + ']');

        console.log("COMP ELEM", queried);

        if (queried.length > 0) {

            let matches: Element[] = Array.prototype.slice.call(queried);
            let element: HTMLElement = evt.target as HTMLElement;
            var index = -1;

            // traverse up the DOM tree until element can't be found in matches array
            while (element && ((index = matches.indexOf(element)) === -1)) {
                element = element.parentElement;
            }

            // when element matches the selector, apply the callback
            if (index > -1) {
                //parsing xclick;
                var def = element.dataset['x' + this.eventType];

                var actions = this.parseAction(def);

                console.log("ELEM", actions);

                if (actions.length > 0) {

                    //wysylamy event do elementu w celu przetworzenia i wywołania akcji
                    this.element.dispatchEvent(
                        DceEngineEvents.liveEvent(
                            this.eventType,
                            actions,
                            evt,
                            element
                        )
                    )

                }

            }
        }


    }

    protected parseAction(def: string): DceLiveEventAction[] {
        let actions: DceLiveEventAction[] = [];

        if (def.trim() != '') {
            let parts: string[] = def.split(';');

            actions = parts
                .map(a => a.trim())
                .filter(a => (a != '' && a.indexOf('::') > 0))
                .map(val => {

                    let part = val.split("::");

                    if (part[0].startsWith('#') && part[0].indexOf('.') > 0) {

                        var withid = part[0].split('.');

                        console.log(withid[0].substr(1));

                        //odfiltrowujemy te które maja inne id
                        if(this.element.id != withid[0].substr(1)){
                            return null;
                        }

                        return {
                            id: withid[0].substr(1),
                            component: withid[1],
                            method: part[1]
                        }

                    }

                    return {
                        id: null,
                        component: part[0],
                        method: part[1]
                    };

                }).filter(a => a != null);

        }

        return actions;
    }


}