import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import DropButtonInput from "ecommerce/lib/app/DropButtonInput";

interface RangeSelectorProps {
    min: number,
    max: number
}

@Dce("RangeSelector")
export default class RangeSelector extends DceComponent<RangeSelectorProps> implements DropButtonInput{

    private rightHandle: HTMLElement;
    private leftHandle: HTMLElement;

    private rightInput: HTMLInputElement;
    private leftInput: HTMLInputElement;

    private left: number
    private right: number

    private leftPx: number;
    private rightPx: number;

    private rangeValue: number;

    private rangeWidth: number;

    private mouseMove = -1;
    private mouseStartX = -1;

    private lane: HTMLElement;

    private initialized: boolean = false;

    constructor(elem: DceHTMLElement, props: RangeSelectorProps) {
        super(elem, props);

    }

    private pixelsAsValue(offsetPixels: number): number {
        return Math.ceil( this.props.min + (offsetPixels / this.rangeWidth) * this.rangeValue );
    }

    private updateFromInputs(){

        let newleft = parseInt(this.leftInput.value);

        if(!Number.isNaN(newleft)){
            this.left = newleft;
        }

        this.left = this.boundsNumer(this.left, this.props.min, this.right);

        let newright = parseInt(this.rightInput.value);

        if(!Number.isNaN(newright)){
            this.right = newright
        }

        this.right = this.boundsNumer(this.right, this.left, this.props.max);

        this.leftInput.value = this.left + '';
        this.rightInput.value = this.right + '';

        this.leftPx = Math.floor(((this.left - this.props.min) / this.rangeValue) * this.rangeWidth) - 10;
        this.rightPx = Math.floor(((this.right - this.props.min) / this.rangeValue) * this.rangeWidth) - 10;

        this.rightPx = this.boundsNumer(this.rightPx, this.leftPx, this.rangeWidth-10);
        this.leftPx = this.boundsNumer(this.leftPx, -10, this.rightPx);

        this.updateInterface();

    }

    private boundsNumer(num:number, min:number, max:number){

        if(num < min){
            return min;
        }

        if(num > max){
            return max;
        }

        return num;
    }

    private updateInterface(){

        console.log("LEFT", this.leftPx + " RIGHT" + this.rightPx);


        this.leftHandle.style.left = this.leftPx + 'px';
        this.rightHandle.style.left = this.rightPx + 'px';

        console.log("RPX", this.rightPx);


        this.lane.style.left = (this.leftPx + 10) + 'px';
        this.lane.style.width = (this.rightPx - this.leftPx) + 'px';

        this.updateInputs();
    }




    private updateInputs() {

        this.leftInput.value = this.pixelsAsValue(this.leftPx + 10) + '';
        this.rightInput.value = this.pixelsAsValue(this.rightPx + 10) + '';

    }

    opened(): void {

        if(this.initialized){
            return;
        }

        console.log("OOOOOPENDE");

        this.initialized = true;

        this.rangeWidth = (this.elem.querySelector('.c-range-select') as HTMLElement).offsetWidth - 20;

        this.rightHandle = this.elem.querySelector('[data-handle-to]');
        this.leftHandle = this.elem.querySelector('[data-handle-from]');

        this.leftInput = this.elem.querySelector('input[data-range-from]');
        this.rightInput = this.elem.querySelector('input[data-range-to]');

        this.lane = this.elem.querySelector('.c-range-select__selected');

        this.rangeValue = this.props.max - this.props.min;

        this.updateFromInputs();

        this.leftInput.addEventListener('change', e => {
            this.updateFromInputs();
        });

        this.rightInput.addEventListener('change', e => {
            this.updateFromInputs();
        });

        let select = this.elem.querySelector('.c-range-select');

        select.addEventListener('mousedown', (e:MouseEvent) => {
            console.log("MMMMMOOOUSE DOWN", e);
            this.mouseDown(e.pageX, e.target);
        });

        select.addEventListener('touchstart', (e:TouchEvent) => {
            console.log("TOUCH START", e);
            this.mouseDown(e.touches[0].clientX, e.touches[0].target);
        });

        document.addEventListener("touchmove", e => {
            if(this.mouseMove > 0){
                this.mouseMoveEvent(e.touches[0].pageX)
            }
        });

        document.addEventListener("mousemove", e => {
            if(this.mouseMove > 0){
                console.log("MOUSE MOVE ", e.pageX);
                this.mouseMoveEvent(e.pageX)
            }
        });

        document.addEventListener("mouseup", e => {
            this.mouseMove = -1;
        });

        document.addEventListener("touchend", e => {
            this.mouseMove = -1;
        });

    }

    private mouseMoveEvent(offsetX:number){
        let select = this.elem.querySelector('.c-range-select');

        var rect = (select as HTMLElement).getBoundingClientRect();

        console.log(offsetX, rect);
        console.log("RANGE WIDTH: ", this.rangeWidth);

        var x = Math.floor(offsetX - rect.left) - 20;

        console.log("XX: ", x);


        if(x < -10){
            x = -10;
        }

        if(x > this.rangeWidth - 10){
            x = this.rangeWidth - 10;
        }



        if(this.mouseMove == 1){

            if(x >= this.rightPx){
                x = this.rightPx-1;
            }

            this.leftPx = x;
        }

        if(this.mouseMove == 2){

            if(x <= this.leftPx){
                x = this.leftPx+1;
            }

            this.rightPx = x;
        }

        this.updateInterface();
    }

    private mouseDown(offsetX: number, target: EventTarget){
        //let me = (e as MouseEvent);

        this.mouseStartX = offsetX;

        if (target == this.leftHandle) {
            console.log("LEEEFT HANDLE!!!");
            this.mouseMove = 1;
            return;
        }

        if (target == this.rightHandle) {
            console.log("RIGHT HANDLE!!!");
            this.mouseMove = 2;

            return;
        }
    }




}
