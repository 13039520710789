import Splide from '@splidejs/splide';


export default class SplideLoader {

    /**
     * 0 - not loaded
     * 1 - loading
     * 2 - loaded
     * @private
     */
    private static status = 0;

    private static promise: Promise<typeof Splide>;

    private static instance:  typeof Splide;

    public static load(): Promise< typeof Splide>{
        if(SplideLoader.status == 0) {
            SplideLoader.status = 1;

            SplideLoader.promise = Promise.all([
                import(/* webpackChunkName: "splide-css" */ '@splidejs/splide/dist/css/themes/splide-default.min.css'),
                import(/* webpackChunkName: "splide" */ '@splidejs/splide'),
            ]).then(v => {

                let dm = v[1];

                let dz_ = (dm.default);
                SplideLoader.instance = dz_;
                SplideLoader.status = 2;
                return dz_;
            });

            return SplideLoader.promise;
        }else if(SplideLoader.status == 1){
            return SplideLoader.promise;
        }else {
            return Promise.resolve(SplideLoader.instance);
        }
    }

}