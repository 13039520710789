
export default class ApiKeys {
    private static instance: ApiKeys;

    private keys: {[k: string]: string} = {};

    static getInstance(): ApiKeys {
        if (!ApiKeys.instance) {
            ApiKeys.instance = new ApiKeys();
        }
        return ApiKeys.instance;
    }


    constructor() {

        let e = document.getElementById('apikeys');
        if(e){
            console.log("API KEY: " + e.innerText.trim());
            let x = atob(e.innerText.trim());

            this.keys = JSON.parse(x);

            console.log("API KEY: " + x);
        }

    }

    public getKey(key:string):string {
        if(this.keys.hasOwnProperty(key)){
            return this.keys[key];
        }
        return null;
    }


}