//TO MUSI BYĆ ABY BUNDLE ZAWIERAŁ KLASY Z components
//TS nie kompiluje nieużywanych klas??
import "dce-engine";
import "dce-ajax"
import "dce-logger";

import "ecommerce"
import { DceEngine } from "dce-engine";

DceEngine.start("data-dce", true);
