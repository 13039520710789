import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import {ApiKeys} from "ecommerce/lib/index";

interface AppProps {
}

@Dce("App")
export default class App extends DceComponent<AppProps>{

    constructor(elem: DceHTMLElement, props: AppProps) {
        super(elem, props);

        DceAjax.getInstance()
            .registerActionHandler('showModal', new ShowModalAction(elem));

        DceAjax.getInstance()
            .registerActionHandler('replaceDiv', new ReplaceDiv(elem));

        DceAjax.getInstance()
            .registerActionHandler('appendDiv', new AppendDiv(elem));


        ApiKeys.getInstance();
    }

}


interface AppendDivItem {
    selector: string;
    content: string;
}

class AppendDiv implements DceAjaxAction {

    private container: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
    }

    execute(data: any): void {

        if(Array.isArray(data)){

            let dd = data as [];
            dd.forEach(v => {

                let d = v as AppendDivItem;

                var template = document.createElement('template');
                d.content = d.content.trim(); // Never return a text node of whitespace as the result
                template.innerHTML = d.content;

                let element = document.querySelector(d.selector);

                if(element){
                    element.append(
                        template.content.firstChild
                    );
                }

            })
        }

    }
}

interface ReplaceDivItem {
    selector: string;
    content: string;
}

class ReplaceDiv implements DceAjaxAction {

    private container: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
    }

    execute(data: any): void {

        if(Array.isArray(data)){

            let dd = data as [];
            dd.forEach(v => {

                let d = v as ReplaceDivItem;

                var template = document.createElement('template');
                d.content = d.content.trim(); // Never return a text node of whitespace as the result
                template.innerHTML = d.content;

                let element = document.querySelector(d.selector);

                if(element){
                    element.parentElement.replaceChild(
                        template.content.firstChild, element
                    );
                }
                
            })
        }

    }
}

class ShowModalAction implements DceAjaxAction {

    private container: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
    }

    execute(data: any): void {

        if(Array.isArray(data)){

            let dd = data as [];
            dd.forEach(v => {

                let html = v as string;

                var template = document.createElement('template');
                html = html.trim(); // Never return a text node of whitespace as the result
                template.innerHTML = html;

                this.container.append(template.content.firstChild);
            })
        }

    }

}