import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";

interface ProductsFilterProps {
    url: string
}

@Dce("ProductsFilter")
export default class ProductsFilter extends DceComponent<ProductsFilterProps>{

    private queryParams: URLSearchParams;

    constructor(elem: DceHTMLElement, props: ProductsFilterProps) {
        super(elem, props);

        console.log("LOCATION", window.location);

        let query = window.location.search;
        this.queryParams = new URLSearchParams(query);

        console.log("DROPS: ",  this.elem.querySelectorAll('.c-dropbutton'));

        this.elem.querySelectorAll('.c-dropbutton')
            .forEach(db => {

                console.log('bind close on', db);

                db.addEventListener('save', e => {
                    console.log("Save event");
                    this.applyFilters()
                })
            });

        this.elem.querySelectorAll('[data-role="open-mobile-filters"]')
            .forEach(openmobile => {

                openmobile.addEventListener('click', e => {
                    let btn = (e.target as HTMLElement);

                    console.log('BBBBBB', btn);

                    if(document.body.classList.contains('c-mobile-filters-opened')){
                        document.body.classList.remove('c-mobile-filters-opened');



                        btn.innerHTML = btn.dataset['openLabel'];
                    }else{
                        document.body.classList.add('c-mobile-filters-opened');

                        btn.innerHTML = btn.dataset['closeLabel'];
                    }

                })
            });

    }


    private applyFilters(){

        console.log("PARAMS!!!", this.queryParams.toString());

        let preQuery = this.queryParams.toString();

        this.elem.querySelectorAll('[data-token]')
            .forEach(subt => {

                let qparam = (subt as HTMLElement).dataset['token'];

                let qvalue: string[] = [];

                subt.querySelectorAll('input[type=checkbox]')
                    .forEach(ch => {
                        if((ch as HTMLInputElement).checked){
                            qvalue.push((ch as HTMLInputElement).value)
                        }
                    })

                let textInputs = subt.querySelectorAll('input[type=text]');
                let allInputValuesAreDefault = true;

                textInputs.forEach(inp => {
                        let i = (inp as HTMLInputElement);

                        if(i.value != ''){
                            qvalue.push(i.name + ":" + i.value);
                        }

                        if(i.dataset['default'] != i.value){
                            allInputValuesAreDefault = false;
                        }
                    });

                if(textInputs.length > 0){
                    if(allInputValuesAreDefault){
                        qvalue = [];
                    }
                }


                this.queryParams.delete(qparam);

                if(qvalue.length > 0) {
                    this.queryParams.set(qparam, qvalue.join(","))
                }

                console.log("PARAMS!!!", this.queryParams.toString());

            });

        let postQuery = this.queryParams.toString();

        console.log("PARAMS 2", this.queryParams.toString());

        if(preQuery != postQuery) {

            if(postQuery.length > 0){
                postQuery = '?' + postQuery;
            }

            window.location.replace(
                this.props.url + postQuery
            );
        }
    }

}
