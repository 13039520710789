import {DceComponent} from "dce-engine";
import DceHTMLElement from "dce-engine/lib/types/DceHTMLElement";
import Dce from "dce-engine/lib/decorators/Dce";
import {DceAjax, DceAjaxAction} from "dce-ajax";
import Modal from "ecommerce/lib/app/Modal";
import SplideLoader from "ecommerce/lib/splide/SplideLoader";
import {Options} from "@splidejs/splide";
//import {SplideOptions} from "@splidejs/splide/types";

interface SplideProps {
    options: Options
}

@Dce("SplideComponent")
export default class SplideComponent extends DceComponent<SplideProps>{

    constructor(elem: DceHTMLElement, props: SplideProps) {
        super(elem, props);

        SplideLoader.load().then(SplideClass => {
            elem.classList.remove('loading');

            if ('requestIdleCallback' in window) {
                requestIdleCallback(() => {
                    new SplideClass(elem, this.props.options as any).mount();
                }, {
                    timeout: 50
                });
            }else{
                setTimeout(() => {
                    new SplideClass(elem, this.props.options as any).mount();
                }, 50);
            }


        });

    }

    defaultProps(): SplideProps {
        return {
            options: {
                type: 'loop',
                autoHeight: true,
                autoplay: true
            }
        }
    }
}
